import React, { useState, useEffect } from 'react';

import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { useIntl } from '../../../../plugins/publicis-gatsby-plugin-i18n';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import IntroPage from '../../../components/ContentType/IntroPage/IntroPage';
import TextVisuelTwoCol from '../../../components/ContentType/TextVisuelTwoCol/TextVisuelTwoCol';
import VisuelTextOverlappedBlock from '../../../components/ContentType/VisuelTextOverlappedBlock/VisuelTextOverlappedBlock';
import KeyNumbers from '../../../components/KeyNumbers/KeyNumbers';
import PageBanner from '../../../components/PageBanner/PageBanner';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import DiscoverOfferBox from '../../../Molecules/DiscoverOfferBox/DiscoverOfferBox';
import ShapeBackground from '../../../Molecules/ShapeBackground/ShapeBackground';
import StickyJobApply from '../../../Molecules/StickyJobApply/StickyJobApply';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';
import { getImage } from '../../../Utils/Utils';
import './talents.scss';

let classNames = require('classnames');

const TalentsPage = ({ data, pageContext }) => {
  const { locale } = pageContext;
  const intl = useIntl();
  const talentsData = data?.talentData?.edges[0]?.node;
  const imagesArray = data?.allImages?.edges;
  const [listsWhyTOjoin, setListsWhyTOjoin] = useState([]);

  let haveAtLeastOneBasicBlock = 0;
  let innerNavArr = [];

  console.log(imagesArray);

  const slider_why_join_settings = {
    className: 'hash_dots_slider',
    centerMode: false,
    infinite: false,
    centerPadding: '5px',
    slidesToShow: 1,
    speed: 500,
    initialSlide: 0,
    arrows: true,
    dots: true,
    customPaging: function (i) {
      return (
        <a>
          {`#${(i + 1)}.`}
          <svg width="36.961" height="6" viewBox="0 0 36.961 6"><path d="M31.654 4.412A10.487 10.487 0 0126.478 6h-16a10.481 10.481 0 01-5.175-1.588A39.3 39.3 0 01-.001 0h36.963a39.323 39.323 0 01-5.308 4.412z" fill="#ffed00"></path></svg>
        </a>
      );
    },
  };

  useEffect(() => {
    let listJoin = [];
    talentsData.relationships.field_blocs.forEach(block => {
      if (block.__typename === 'block_content__basic') {
        listJoin.push(
          {
            title: block?.field_title?.processed,
            text: block?.body?.processed,
            imagePadding: block?.field_no_padding,
            imageAlignement: block?.field_alignment,
            visuel: getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp
          }
        );
      }
    });
    console.log(listJoin)
    setListsWhyTOjoin(listJoin);
  }, []);

  const getSectionName = (title) => {
    let secName = '';

    innerNavArr.forEach((item) => {
      if (item.title === title) secName = item.section; return false;
    });
    return secName;
  };

  // block_content__block_chiffres_clefs
  // block_content__block_chiffres_clefs

  const setInnerNav = (classname_, title) => {
    let t = title?.replace(/<[^>]*>?/gm, '');
    if (innerNavArr.filter(a => a?.section?.includes(classname_) === true).length <= 0) {
      innerNavArr.push({
        title: t,
        section: classname_
      });
    }
  };

  return (
    <Layout imagesArray={imagesArray}>
      <Seo
        title={talentsData?.title}
        description={talentsData?.body?.processed}
      />
      <div className={classNames('page_template', 'zero_bottom_padding', 'page_talents')}>

        <ScrollPageNav
          data={innerNavArr}
        />
        <PageBanner visuel={getImage(imagesArray, talentsData?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp?.fluid} scroll={true}>
          <div className="wrapper_page">
            <Breadcrumb
              page_banner={true}
              data={{
                currentPage: { title: talentsData?.title, url: '' },
                locale: talentsData.langcode
              }}
            />
            <TitlePage
              color="color_white"
              title={talentsData?.title}
              description={talentsData?.body?.processed}
            />
          </div>
        </PageBanner>

        {talentsData.relationships.field_blocs.map((block, i) => {
          switch (block.__typename) {
            case 'block_content__block_contact':
              return (
                <div key={i}>
                  {block?.field_type_bloc_contact === 'sticky' && <StickyJobApply

                    text={block?.body?.processed}
                    buttonLabel={intl.formatMessage({ id: 'talent.button.apply.label' })}
                    buttonLink={block?.field_link?.url}
                    target={block?.field_nouvelle_fenetre && '_blank'}
                  />}
                </div>

              );

            case 'block_content__block_edito':
              setInnerNav('intro_page_component', block?.field_title?.processed);
              return (

                <IntroPage
                  key={i}
                  title={block?.field_title?.processed}
                  subtitle={block?.field_subtitle?.processed}
                  visuel={getImage(imagesArray, block.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                  text={null}
                  size="wrapper" //border
                  quote={{
                    lang: locale,
                    text: block?.field_citation/*,
                    name: 'Prénom Nom'*/
                  }}
                />

              );

            case 'block_content__basic':
              haveAtLeastOneBasicBlock++;
              haveAtLeastOneBasicBlock === 1 && setInnerNav('section_index_' + i, listsWhyTOjoin.filter(a => a.title != null)[0]?.title);
              let title = listsWhyTOjoin.filter(a => a.title != null)[0]?.title;
              return (
                <div key={i}>
                  {haveAtLeastOneBasicBlock === 1 && <section className={classNames('section_content section_lists_why_to_join', getSectionName(title))}>
                    <ShapeBackground
                      top="right"
                      bottomInfinite={true}
                      color="catskill_white"
                    >
                      <div className="wrapper_page_xs">
                        <TitleSection
                          title={title}
                          type="arrow"
                        />
                      </div>
                      <div className="only_mobile">
                        <SliderComponent settings={slider_why_join_settings}>
                          {listsWhyTOjoin.map((whyToJoinItem, i) => (
                            <div key={i}>
                              <div className="visuel">
                                {whyToJoinItem?.visuel &&
                                  <Img
                                    style={{ width: '100%' }}
                                    fluid={{ ...whyToJoinItem.visuel.mobile, aspectRatio: 1.27303754266 }}
                                    imgStyle={{ objectFit: 'cover' }}
                                  />
                                }
                              </div>
                              <div className="upper_text">
                                <h5 className="title_hash" dangerouslySetInnerHTML={{ __html: whyToJoinItem.title }}></h5>
                                <div dangerouslySetInnerHTML={{ __html: whyToJoinItem.text }}></div>
                              </div>
                            </div>
                          ))}
                        </SliderComponent>
                      </div>
                      <div className="only_tablet_desktop wrapper_page_xs">
                        {
                          listsWhyTOjoin.map((whyToJoinItem, index) => (
                            <TextVisuelTwoCol
                              key={index}
                              orderInverse={whyToJoinItem?.imageAlignement === 'right' ? true : false}
                              extremeLeft={index === 0}
                              visuel={whyToJoinItem?.visuel}
                              titleHash={false}
                              // title={'#' + (index + 1) + ' ' + whyToJoinItem.title}
                              text={whyToJoinItem?.text}
                            />
                          ))
                        }
                      </div>
                    </ShapeBackground>
                  </section>}
                </div>
              );

            case 'block_content__block_chiffres_clefs':
              // eslint-disable-next-line no-case-declarations
              const dataArr = [];
              block.relationships?.field_values?.forEach((item) => {
                dataArr.push({
                  number: item.field_chiffre,
                  title: item.field_legend?.processed
                });
              });
              setInnerNav('key_numbers_component', block.field_title?.processed);
              return (

                <KeyNumbers
                  key={i}
                  title={block.field_title?.processed}
                  subtitle={block.body?.processed?.replace(/<p[^>]*>/g, '')}
                  sectionName={'key_numbers_component ' + getSectionName(block.field_title?.processed)}
                  textColor="white"
                  backgroundImg={getImage(imagesArray, block.relationships?.field_image?.drupal_internal__mid)?.publicURL}
                  datas={dataArr}
                  locale={locale}
                />
              );

            case 'block_content__block_cta_avec_image_de_fond':
              setInnerNav('collapse_with_bottom_section', 'hide');
              return (
                <div key={i}>
                  <section className={classNames('section_content', 'collapse_with_bottom_section')}>

                    <div className="wrapper_page">
                      <DiscoverOfferBox
                        visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                        label={block?.field_link?.title}
                        link={block?.field_link?.uri}
                        target={block?.field_nouvelle_fenetre ? '_blank' : '_self'}
                      />
                    </div>

                  </section>
                </div>

              );

            case 'block_content__block_texte':
              setInnerNav('section_human_resources', block.field_title?.processed);

              return (
                <section className={classNames('section_content section_human_resources special_top_marge', getSectionName(block.field_title?.processed))} key={i}>
                  <ShapeBackground
                    top="left"
                    bottom="left"
                    color="bleu"
                  >

                    <div className="wrapper_page_xs">
                      <TitleSection
                        title={block?.field_title?.processed}
                        description={block?.field_subtitle?.processed}
                        type="arrow"
                        h2color='white'
                        h4color="yellow"
                      />
                    </div>
                    <VisuelTextOverlappedBlock
                      extremeLeft={true}
                      visuel={getImage(imagesArray, block?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                      alt=""
                      bgColor="color_sky_bleu"
                      //title="Respect, Partage et Audace."
                      text={block?.body?.processed}
                    />
                    <div className="wrapper_page_xs">
                      {block?.relationships?.field_section.map((section, j) => (
                        <TextVisuelTwoCol
                          key={j}
                          orderInverse={true}
                          visuel={getImage(imagesArray, section?.relationships?.field_image?.drupal_internal__mid)?.childImageSharp}
                          extactHalf={true}
                          colorTitle="color_white"
                          colorDescription="color_white"
                          text={section?.relationships?.field_bloc_texte[0]?.field_bloc_text_1?.processed}

                        />
                      ))}

                    </div>
                  </ShapeBackground>
                </section>
              );

            default:
              break;
          }
        })}
      </div>
    </Layout>
  );
};

export const query = graphql`
query TalentTemplateQuery($locale: String!, $slug: String!, $imagesID: [Int!]) {
  talentData: allNodePage(
    filter: {langcode: {eq: $locale}, path: {alias: {eq: $slug}}}
  ) {
    edges {
      node {
        title
        langcode
        path {
          alias
        }
        relationships {
          field_image {
            drupal_internal__mid
          }
          field_blocs {
            __typename
            ... on Node {
              ... on block_content__basic {
                id
                field_alignment
                field_no_padding
                field_title {
                  processed
                }
                body {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_edito {
                id
                field_title {
                  processed
                }
                field_subtitle {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
                field_citation
              }
              ... on block_content__basic {
                field_no_padding
                body {
                  processed
                }
                field_title {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_cta_avec_image_de_fond {
                field_link {
                  title
                  uri
                }
                field_nouvelle_fenetre
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                }
              }
              ... on block_content__block_texte {
                id
                field_title {
                  processed
                }
                body {
                  processed
                }
                field_subtitle {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_section {
                    relationships {
                      field_image {
                        drupal_internal__mid
                      }
                      field_bloc_texte {
                        field_bloc_text_1 {
                          processed
                        }
                      }
                    }
                  }
                }
              }
              ... on block_content__block_contact {
                field_type_bloc_contact
                field_nouvelle_fenetre
                body {
                  processed
                }
                field_link {
                  url
                }
              }
              ... on block_content__block_chiffres_clefs {
                body {
                  processed
                }
                field_title {
                  processed
                }
                relationships {
                  field_image {
                    drupal_internal__mid
                  }
                  field_values {
                    field_legend {
                      processed
                    }
                    field_chiffre
                  }
                }
              }
            }
          }
        }
        body {
          processed
        }
      }
    }
  }
  allImages: allMediaImage(
    filter: {langcode: {eq: "fr"}, drupal_internal__mid: {in: $imagesID}}
  ) {
    edges {
      node {
        drupal_internal__mid
        relationships {
          field_media_image {
            localFile {
              publicURL
              childImageSharp {
                mobile: fixed(width: 456, height: 456) {
                  ...GatsbyImageSharpFixed
                }
                desktop: fixed(width: 1256, height: 814) {
                  ...GatsbyImageSharpFixed
                }
                fluid {
                  ...GatsbyImageSharpFluid
                }

              }
            }
          }
        }
        langcode
      }
    }
  }
}
`;

export default TalentsPage;
