import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import VisuelComponent from '../../components/ContentType/VisuelComponent/VisuelComponent';
import { Button } from '../Button/Button';
import './DiscoverOfferBox.scss';

const DiscoverOfferBox = ({ visuel, label = 'Découvrez nos offres-', link = '/', target = '_self' }) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.innerWidth < 1024 ? true : false);
  }, []);

  return (
    <div className="discover_offer_box_component">
      <VisuelComponent
        visuel={visuel}
        alt="Test"
        aspectRationValue={isMobile ? 374 / 430 : false}
      />
      <div className="overlay">
        <Button
          primary={true}
          label={label}
          link={link}
          target={target}
        />
      </div>
    </div>
  );
};

DiscoverOfferBox.defaultProps = {
  //label: 'Default Label',
};

export default DiscoverOfferBox;
